const vendemmiaOperationUnitValues = [
    {
        label:"ESPÍRITO SANTO - VITÓRIA",
        value: 13631538000146
    },
    {
        label:"PERNAMBUCO - RECIFE",
        value: 13631538000499
    },
    {
        label:"MINAS GERAIS - POUSO ALEGRE",
        value: 13631538000650
    },
    {
        label:"SANTA CATARINA - ITAJAÍ",
        value: 13631538000308
    },
    {
        label:"SÃO PAULO - SÃO PAULO",
        value: 13631538000227
    },
];

export default vendemmiaOperationUnitValues;